import { allowAdditionalProperties } from "~lib/type-utils/allowAdditionalProperties";

export type VenueLike = allowAdditionalProperties<{
  name: string;
  address: {
    city: string;
  };
}>;

export function getEventVenueLocation(venue: VenueLike | undefined) {
  return venue?.name ? `${venue?.name}, ${venue?.address?.city}` : "";
}
